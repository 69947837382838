/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';
import {
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  AboutMeRoutes,
  ClaimsRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  MessagingRoutes,
  Advocate,
  DashboardRoutes,
} from 'components/LazyRoutes';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { BasePathRedirect } from '@xo-health-web/shared-module';

import ErrorPage from 'pages/Error404';
import { AuthSignIn } from 'pages/auth/auth-sign-in.page';
import { AuthSignOut } from 'pages/auth/auth-sign-out.page';
import { PreLoginLanding } from 'pages/pre-login-landing.page';
import { PersonalInformationPage } from 'pages/PersonalInformationPage';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { CommunicationPreferences } from 'pages/communication-preferences.page';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';
import { PrivateRoutesContainer } from './private-routes-container/private-routes-container.component';

function AppRoutes() {
  return (
    <Switch>
      <Route path={APP_MANAGED_ROUTES.SIGN_IN} component={AuthSignIn} />
      <Route path={APP_MANAGED_ROUTES.SIGN_OUT} component={AuthSignOut} />
      <Route path="/" exact component={PreLoginLanding} />
      <AuthSessionContainer>
        <PrivateRoutesContainer>
          <Switch>
            <Route path={APP_MANAGED_ROUTES.MEMBER_REDIRECT}>
              <BasePathRedirect targetBasePath="" />
            </Route>
            <Route
              path={APP_MANAGED_ROUTES.HOME}
              component={Home}
              exact
              key="home"
            />
            <Route
              path={[
                getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
              ]}
              component={HealthJourneyRoutes}
              key="health-journey"
            />
            <Route
              path={`${getModulePath(
                LEAGUE_MODULE_NAMES.healthProfile,
              )}/insights`}
              component={DashboardRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
              key="care-collections"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={CareDiscoveryRoutes}
              key="care-discovery"
            />
            <Route
              path={APP_MANAGED_ROUTES.ADVOCATE}
              component={Advocate}
              key="advocate"
            />
            <Route
              path={APP_MANAGED_ROUTES.COMMUNICATION_PREFERENCES}
              component={CommunicationPreferences}
              key="communication-preferences"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
              component={HealthNeedPages}
              key="health-needs"
            />
            <Route
              path={APP_MANAGED_ROUTES.PERSONAL_INFO}
              component={PersonalInformationPage}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
              key="wallet"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={AboutMeRoutes}
              key="about-me"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
              component={ClaimsRoutes}
              key="claims"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
              component={OnboardingRoutesWithProvider}
              key="onboarding"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
              component={MessagingRoutes}
              key="messaging"
            />
            <Route component={ErrorPage} />
          </Switch>
        </PrivateRoutesContainer>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
