export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'xo-health-web',
};

export const COMMUNICATION_OPTIONS = {
  EMAIL: 'email',
};

export const SUB_PRODUCT_AREA_NAMES = {
  FOOTER: 'footer',
};
